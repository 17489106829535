<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="RELATED_LAWS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="relatedLawsCd"
            label="LBL0010245"
            v-model="searchParam.relatedLawsCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <!-- 인허가 현황 목록 -->
    <c-table
      ref="table"
      title="LBL0010246"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :columnSetting="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :selection="popupParam.type"
      rowKey="limLicenseId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <!-- 선택 -->
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'license-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          limLicenseKindId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        relatedLawsCd: null,
        limLicenseKindId: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'licenseNo',
            field: 'licenseNo',
            //인허가번호
            label: 'LBL0010248',
            align: 'center',
            sortable: true,
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            //관련법규
            label: 'LBL0010245',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            //인허가종류
            label: 'LBL0010249',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'licenseStartDate',
            field: 'licenseStartDate',
            //인허가시작일
            label: 'LBL0010250',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'licenseEndDate',
            field: 'licenseEndDate',
            //인허가종료일
            label: 'LBL0010251',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'relatedDeptName',
            field: 'relatedDeptName',
            //관련부서
            label: 'LBL0010252',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'licenseCreateTypeName',
            field: 'licenseCreateTypeName',
            //인허가 등록 구분
            label: 'LBL0010253',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
        ],
        data: [],
        height: '400px',
      },
      editable: true,
      listUrl: '',
      kindlistUrl: '',
    };
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 150);
      if (height < 400) {
        height = 400;
      }
      this.grid.height = height + 'px'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.lim.lincense.card.list.url;
      // code setting
      if (this.popupParam.plantCd) {
        this.$set(this.searchParam, 'plantCd', this.popupParam.plantCd)
      }
      if (this.popupParam.limLicenseKindId) {
        this.$set(this.searchParam, 'limLicenseKindId', this.popupParam.limLicenseKindId)
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010019', // 인허가를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
